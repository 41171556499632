import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import styleded from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// MUI Table
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// MUI Pagination
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axiosInstance from "../api/axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Journals = () => {
  const [journals, setJournals] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/api/Journal?pageNumber=${pageNumber}&pageSize=10`)
      .then((res) => {
        setJournals(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageNumber]);

  function setOpenBook(props) {
    navigate(`/single-journal/${props}`);
  }

  function paginationFunc(e, p) {
    setPageNumber(p - 1);
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <JournalsBox>
          <h3>Qo'lyozmalar</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>№</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Nomi</StyledTableCell>
                  <StyledTableCell>Jurnal turi</StyledTableCell>
                  <StyledTableCell>Muassasa</StyledTableCell>
                  <StyledTableCell align="right">
                    Batafsil ma'lumot olish
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {journals?.items &&
                  journals?.items.map((row, index) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {++index}
                      </StyledTableCell>
                      <StyledTableCell>
                        <img
                          src={`${axiosInstance.getUri()}api/file/downloadimagefile/${
                            row?.imageId
                          }/low`}
                          alt="Journal"
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row?.name}</StyledTableCell>
                      <StyledTableCell>
                        {row?.journalType?.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.institution?.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <button
                          onClick={() => setOpenBook(row?.id)}
                          className="btn btn-dark"
                        >
                          Batafsil
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <JournalsPaginationBox>
            <Stack spacing={2}>
              <Pagination
                onChange={paginationFunc}
                count={journals?.totalPages}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </JournalsPaginationBox>
        </JournalsBox>
      </div>
      <Footer />
    </div>
  );
};

const JournalsBox = styleded.div`
    margin-top: 95px;

    h3 {
        margin-bottom: 20px;
    }
            img {
        height: 100px;
    }
`;

const JournalsPaginationBox = styleded.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

`;

export default Journals;
