import React, { useState } from 'react'
import styled from 'styled-components'
import logo from '../images/icon-512.png';
import { Link } from 'react-router-dom';
import { BsFillInfoSquareFill } from "react-icons/bs";
import { FaHistory, FaJournalWhills, FaNetworkWired } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import BulidingImage from '../images/navbar/section/buliding.avif';
import { FaBookOpen } from "react-icons/fa6";
import { GiBookshelf } from "react-icons/gi";
import { SiGitbook } from "react-icons/si";
import { AiFillBook } from "react-icons/ai";
import { GiSpellBook } from "react-icons/gi";
import Header from './header';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './selectLanguage';
import { useSelector } from 'react-redux';
import { UserStore } from "../store/user";
import { useDispatch } from "react-redux";
import Image1 from '../images/navbar/branchs/nashriyot.webp';
import Image2 from '../images/navbar/branchs/kataloglashtirish.webp';
import Image3 from '../images/navbar/branchs/service.webp';
import Image4 from '../images/navbar/branchs/electron.webp';
import Image5 from '../images/navbar/branchs/forign.webp';
import BranchImage1 from '../images/navbar/branchs/branch-1.jpg'


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Navbar() {
    const [showdropdown, setShowDropDown] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userInformation = useSelector((user) => user?.user?.user);
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const BookCategory = searchParams.get('Category');
    const dispatch = useDispatch();
    const [mobNav1, setMobNav1] = useState(false);
    const [mobNav2, setMobNav2] = useState(false);
    const [mobNav3, setMobNav3] = useState(false);
    const [mobNav4, setMobNav4] = useState(false);
    const [mobNav5, setMobNav5] = useState(false);
    const Role = localStorage.getItem('role');




    function dropdownShow() {
        setShowDropDown(!showdropdown)
    }


    function handleClickProfile() {
        navigate('/login')
        setShowDropDown(!showdropdown);
    }

    function mobileNavHome() {
        navigate('/');
    }

    function handleExit() {
        localStorage.removeItem('token');
        dispatch(UserStore(null));
        setShowDropDown(!showdropdown);
        toast.success('Tizimdan chiqdingiz!');
        navigate('/');
    }


    function handleMobileBtn() {
        setMobileNav(!mobileNav);
    }

    function handleNavItem1() {
        setMobNav1(!mobNav1)
    }

    function handleNavItem2() {
        setMobNav2(!mobNav2)
    }

    function handleNavItem3() {
        setMobNav3(!mobNav3)
    }

    function handleNavItem4() {
        setMobNav4(!mobNav4)
    }

    function handleNavItem5() {
        setMobNav5(!mobNav5)
    }

    function handleAttendane() {
        navigate('/attendance');
    }

    return (
        <>
            <Nav>
            <div className='container'>
                <NavGird>
                    <Link to='/'>
                        <NavLeft>
                            <NavLogo>
                                <img src={logo} alt='logo-img' />
                            </NavLogo>
                            <MainTitle>
                                <h3>{t('MAIN_HEADER')}<br /><span>{t('MAIN_HEADER2')}</span></h3>
                            </MainTitle>
                        </NavLeft>
                    </Link>
                    <NavCenter>
                        <NavDesktop>
                            <ul>
                                <li>
                                    <Link to='/'>
                                        {t('HOME')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/'>
                                        {t('CENTER')}
                                    </Link>
                                    <NavCenterChild>
                                        <ul>
                                            <Link style={{ color: '#000' }} to={'/center-about'}>
                                                <NavCenterItem>
                                                    <NavCenterImg>
                                                        <h5>
                                                            <BsFillInfoSquareFill />
                                                        </h5>
                                                    </NavCenterImg>
                                                    <NavCenterTitle>
                                                        <p>{t('ABOUT_CENTER')}</p>
                                                    </NavCenterTitle>
                                                </NavCenterItem>
                                            </Link>
                                            <Link style={{ color: '#000' }} to={'/center-history'}>
                                                <NavCenterItem>
                                                    <NavCenterImg>
                                                        <h5>
                                                            <FaHistory />
                                                        </h5>
                                                    </NavCenterImg>
                                                    <NavCenterTitle>
                                                        <p>{t('HISTORY_CENTER')}</p>
                                                    </NavCenterTitle>
                                                </NavCenterItem>
                                            </Link>
                                            <Link style={{ color: '#000' }} to={'/arm-terms'}>
                                                <NavCenterItem>
                                                    <NavCenterImg>
                                                        <h5>
                                                            <FaUserGraduate />
                                                        </h5>
                                                    </NavCenterImg>
                                                    <NavCenterTitle>
                                                        <p>{t('TERMS_OF_USE_ARM')}</p>
                                                    </NavCenterTitle>
                                                </NavCenterItem>
                                            </Link>
                                        </ul>
                                    </NavCenterChild>
                                </li>
                                <li>
                                    <Link to='/'>
                                        {t('SECTIONS')}
                                    </Link>
                                    <NavSectionChild>
                                        {/* <HeaderStyle style={{fontSize: '12px'}}>
                                            <Header title={t('BRANCHS')} />
                                        </HeaderStyle>
                                        <Branches>
                                            <Link style={{color: "#000"}} to='/branch-1'>
                                                <BranchesItem>
                                                    <BranchesImg>
                                                        <h5>
                                                            <img src={BranchImage1} alt='branches_image' />
                                                        </h5>
                                                    </BranchesImg>
                                                    <BranchesTitle>
                                                        <p>{t('BRANCH1_HEADER')}</p>
                                                    </BranchesTitle>
                                                </BranchesItem>
                                            </Link>
                                            <Link style={{color: "#000"}} to='/branch-2'>
                                                <BranchesItem>
                                                    <BranchesImg>
                                                        <h5>
                                                            <img src={BulidingImage} alt='branches_image' />
                                                        </h5>
                                                    </BranchesImg>
                                                    <BranchesTitle>
                                                        <p>Branche Name</p>
                                                    </BranchesTitle>
                                                </BranchesItem>
                                            </Link>
                                        </Branches> */}
                                        <HeaderStyle>
                                            <Header title={t('SECTIONS')} />
                                        </HeaderStyle>
                                        <ul>
                                            <Link to={'/publishing_deportment'} style={{color: '#000'}}>
                                                <NavSectionItem>
                                                    <NavSectionImg>
                                                        <h5>
                                                            <img src={Image1} alt='building_image' />
                                                        </h5>
                                                    </NavSectionImg>
                                                    <NavSectionTitle>
                                                        <p>{t('PERIODICALS')}</p>          
                                                    </NavSectionTitle>
                                                </NavSectionItem>
                                            </Link>
                                            <Link to={'/gathering-resrces'} style={{color: '#000'}}>
                                                <NavSectionItem>
                                                    <NavSectionImg>
                                                        <h5>
                                                            <img src={Image2} alt='building_image' />
                                                        </h5>
                                                    </NavSectionImg>
                                                    <NavSectionTitle>
                                                        <p>{t('INFORMATION_LIBRARY')}</p>          
                                                    </NavSectionTitle>
                                                </NavSectionItem>
                                            </Link>
                                            <Link to={'/library-resources'} style={{color: '#000'}}>
                                                <NavSectionItem>
                                                    <NavSectionImg>
                                                        <h5>
                                                            <img src={Image3} alt='building_image' />
                                                        </h5>
                                                    </NavSectionImg>
                                                    <NavSectionTitle>
                                                        <p>{t('LIBRARY_SERVICE')}</p>          
                                                    </NavSectionTitle>
                                                </NavSectionItem>
                                            </Link>
                                            <Link to={'/electronic-information'} style={{color: '#000'}}>
                                                <NavSectionItem>
                                                    <NavSectionImg>
                                                        <h5>
                                                            <img src={Image4} alt='building_image' />
                                                        </h5>
                                                    </NavSectionImg>
                                                    <NavSectionTitle>
                                                        <p>{t('ELECTRON_LIBRARY')}</p>          
                                                    </NavSectionTitle>
                                                </NavSectionItem>
                                            </Link>
                                            <Link to={'/foreign-library'} style={{color: '#000'}}>
                                                <NavSectionItem>
                                                    <NavSectionImg>
                                                        <h5>
                                                            <img src={Image5} alt='building_image' />
                                                        </h5>
                                                    </NavSectionImg>
                                                    <NavSectionTitle>
                                                        <p>{t('DEPARTMENT_LIBRARY')}</p>          
                                                    </NavSectionTitle>
                                                </NavSectionItem>
                                            </Link>
                                        </ul>
                                    </NavSectionChild>
                                </li>
                                <li>
                                    <Link to='/'>
                                        {t('ELECTRON_BOOKS')}
                                    </Link>
                                    <Electron>
                                        <ul>
                                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${1}&pageNumber=${0}&pageSize=${12}`} style={{color: '#000'}}>
                                                <ElectronItem>
                                                    <ElectronImg>
                                                        <h5>
                                                            <FaBookOpen />
                                                        </h5>
                                                    </ElectronImg>
                                                    <ElectronTitle>
                                                        <p>{t('BOOKS')}</p>
                                                    </ElectronTitle>
                                                </ElectronItem>
                                            </Link>
                                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${4}&pageNumber=${0}&pageSize=${12}`} style={{color: '#000'}}>
                                                <ElectronItem>
                                                    <ElectronImg>
                                                        <h5>
                                                            <GiBookshelf />
                                                        </h5>
                                                    </ElectronImg>
                                                    <ElectronTitle>
                                                        <p>{t('ARTICLES')}</p>
                                                    </ElectronTitle>
                                                </ElectronItem>
                                            </Link>
                                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${3}&pageNumber=${0}&pageSize=${12}`} style={{color: '#000'}}>
                                                <ElectronItem>
                                                    <ElectronImg>
                                                        <h5>
                                                            <SiGitbook />
                                                        </h5>
                                                    </ElectronImg>
                                                    <ElectronTitle>
                                                        <p>{t('DISSERTATIONS')}</p>
                                                    </ElectronTitle>
                                                </ElectronItem>
                                            </Link>
                                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=${2}&pageNumber=${0}&pageSize=${12}`} style={{color: '#000'}}>
                                                <ElectronItem>
                                                    <ElectronImg>
                                                        <h5>
                                                            <AiFillBook />
                                                        </h5>
                                                    </ElectronImg>
                                                    <ElectronTitle>
                                                        <p>{t('MONOGRAPHS')}</p>
                                                    </ElectronTitle>
                                                </ElectronItem>
                                            </Link>
                                            <Link to={'/menuscripts'} style={{color: '#000'}}>
                                                <ElectronItem>
                                                    <ElectronImg>
                                                        <h5>
                                                            <GiSpellBook />
                                                        </h5>
                                                    </ElectronImg>
                                                    <ElectronTitle>
                                                        <p>{t('MANUSCRIPTS')}</p>
                                                    </ElectronTitle>
                                                </ElectronItem>
                                            </Link>
                                            <Link to={'/internationalbase'} style={{color: '#000'}}>
                                                <ElectronItem>
                                                    <ElectronImg>
                                                        <h5>
                                                            <FaNetworkWired />
                                                        </h5>
                                                    </ElectronImg>
                                                    <ElectronTitle>
                                                        <p>{t('PARTNERS')}</p>
                                                    </ElectronTitle>
                                                </ElectronItem>
                                            </Link>
                                            <Link to={'/journals'} style={{color: '#000'}}>
                                                <ElectronItem>
                                                    <ElectronImg>
                                                        <h5>
                                                            <FaJournalWhills  />
                                                        </h5>
                                                    </ElectronImg>
                                                    <ElectronTitle>
                                                        <p>{t('Journals')}</p>
                                                    </ElectronTitle>
                                                </ElectronItem>
                                            </Link>
                                        </ul>
                                    </Electron>
                                </li>
                                {/* <li>
                                    <Link to='/order-book'>
                                        {t('ORDER_A_BOOK')}
                                    </Link>
                                </li> */}
                            </ul>
                        </NavDesktop>
                        <div className='mobile'>

                        </div>
                    </NavCenter>
                    <NavRight>
                        <SelectLanBox>
                            <LanguageSelector />
                        </SelectLanBox>
                        {Role == 2 ? 
                            <AttendanceNav>
                                <button onClick={handleAttendane} className='btn btn-success'>{t('ATTENDANCE')}</button>
                            </AttendanceNav>
                            :
                            null
                        }
                        <NavProfile>
                            {userInformation?.accessToken ?
                                <button onClick={dropdownShow} className='btn'><i class='bx bx-user-circle'></i></button>
                                :
                                <button className='btn btn-success' onClick={handleClickProfile}>{t('ENTER')}</button>
                            }
                            <MobileNav>
                                <MobileNavButton>
                                    <button style={{color: '#fff'}}>
                                        <label class="hamburger">
                                            <input type="checkbox" onClick={handleMobileBtn} />
                                            <svg viewBox="0 0 32 32">
                                                <path class="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
                                                <path class="line" d="M7 16 27 16"></path>
                                            </svg>
                                        </label>
                                    </button>
                                </MobileNavButton>
                            </MobileNav>
                            {showdropdown &&
                                <NavDropDownProfile>
                                    {userInformation?.accessToken ? 
                                        <>
                                            <button onClick={() => navigate('/profile')} className='btn btn-success w-100 mb-1'>{t('PROFILE')}</button>
                                            <button onClick={handleExit} className='btn btn-success w-100'>{t('EXIT')}</button>
                                        </>
                                        :
                                        null
                                    }
                                </NavDropDownProfile>
                            }
                        </NavProfile>
                    </NavRight>
                </NavGird>
            </div>
        </Nav>
        {mobileNav && 
            <MobileNavBox>
                <MobileNavMain>
                    <button onClick={mobileNavHome}>{t('HOME')}</button>
                    <button onClick={handleNavItem1}>{t('CENTER')}</button>
                    {mobNav1 &&
                        <ul>
                            <Link to='/center-about'>
                                <li>{t('ABOUT_CENTER')}</li>
                            </Link>
                            <Link to='/center-history'>
                                <li>{t('HISTORY_CENTER')}</li>
                            </Link>
                            <Link to='/arm-terms'>
                                <li>{t('TERMS_OF_USE_ARM')}</li>
                            </Link>
                        </ul>
                    }
                    <button onClick={handleNavItem5}>{t('SECTIONS')}</button>
                    {mobNav5 && 
                        <ul>
                            <li>
                                <button onClick={handleNavItem2}>{t('BRANCHS')}</button>
                                {mobNav2 && 
                                    <ul>
                                        <Link to='/branch-1'>
                                            <li>Branche name</li>
                                        </Link>
                                        <Link to='/branch-2'>
                                            <li>Branche name</li>
                                        </Link>
                                    </ul>
                                }
                            </li>
                            <li>
                                <button onClick={handleNavItem3}>{t('SECTIONS')}</button>
                                {mobNav3 && 
                                    <ul>
                                        <Link to='/publishing_deportment'>
                                            <li>{t('PERIODICALS')}</li>
                                        </Link>
                                        <Link to='/gathering-resrces'>
                                            <li>{t('INFORMATION_LIBRARY')}</li>
                                        </Link>
                                        <Link to='/library-resources'>
                                            <li>{t('LIBRARY_SERVICE')}</li>
                                        </Link>
                                        <Link to='/electronic-information'>
                                            <li>{t('ELECTRON_LIBRARY')}</li>
                                        </Link>
                                        <Link to='/foreign-library'>
                                            <li>{t('DEPARTMENT_LIBRARY')}</li>
                                        </Link>
                                        <Link to='https://medieval.uz/'>
                                            <li>Elektron lug'at</li>
                                        </Link>
                                    </ul>
                                }
                            </li>
                        </ul>
                    }
                    <button onClick={handleNavItem4}>{t('ELECTRON_BOOKS')}</button>
                    {mobNav4 && 
                        <ul>
                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=1&pageNumber=0&pageSize=12`}>
                                <li>{t('BOOKS')}</li>
                            </Link>
                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=4&pageNumber=0&pageSize=12`}>
                                <li>{t('ARTICLES')}</li>
                            </Link>
                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=3&pageNumber=0&pageSize=12`}>
                                <li>{t('DISSERTATIONS')}</li>
                            </Link>
                            <Link to={`/order-book/?title=&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=2&pageNumber=0&pageSize=12`}>
                                <li>{t('MONOGRAPHS')}</li>
                            </Link>
                            <Link to={`/menuscripts`}>
                                <li>{t('MANUSCRIPTS')}</li>
                            </Link>
                            <Link to={`/internationalbase`}>
                                <li>{t('PARTNERS')}</li>
                            </Link>
                            {/* <Link to='/'>
                                <li>Postlar</li>
                            </Link> */}
                            <Link to='/news'>
                                <li>{t('NEWS')}</li>
                            </Link>
                        </ul>
                    }
                </MobileNavMain>
            </MobileNavBox>
        }
        </>
    )
}


const Nav = styled.div`
    width: 100%;
    min-height: 60px;
    background: rgb(4, 8, 55);
    padding: 5px;
    position: fixed;
    top: 0;
    z-index: 1000;
`
const NavGird = styled.div`
    display: grid;
    grid-template-columns: 3fr 6fr 2fr;

    > a {
        display: flex;
        align-items: center;
    }

    @media (max-width: 576px) {
        grid-template-columns: 6fr 10fr;
    }
`
const NavLeft = styled.div`
    display: grid;
    grid-template-columns: 3fr 7fr;
`
const NavLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        width: 70px;
    }

    @media (max-width: 576px) {
        > img {
            width: 40px;
        }
    }
`
const MainTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;

    h3 {
        font-size: 18px;
        color: #fff;
        padding: 0;
        margin: 0;

        span {
            color: #5cb85c;
        }
    }

    @media (max-width: 576px) {
        h3 {
            font-size: 12px;
        }
    }
`

const NavCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
        display: none;
    }
`
const NavDesktop = styled.div`
    width: 100%;
    
    & > ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        > li {
            color: #fff;
            padding-right: 10px;
            transition: all 0.5s;

            > div {
                position: absolute;
                top: 60px;
                left: 50%;
                transform: translateX(-50%);
                min-width: 600px;
                min-height: 100px;
                background-color: #fff;
                visibility: hidden;
                transition: all 0.5s;
                opacity: 0;
                box-shadow: 1px 1px 4px 3px rgb(0, 0, 0, 0.1);
                color: black;
                border-radius: 8px;
            }
            
            &::before {
                content: '';
                position: absolute;
                top: 60px;
                width: 0px;
                height: 2px;
                background-color: #5cb85c;
                transition: all 0.5s;
            }

            &:hover {
                cursor: pointer;

                &::before {
                    width: 50px;
                }

                div {
                    visibility: inherit;
                    top: 80px;
                    transition: all 0.5s;
                    opacity: 1;
                }
            }
        }
    }

    @media (max-width: 576px) {
        display: none;
    }
`

const NavRight = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const AttendanceNav = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
        display: none;


        button {
            padding: 5px 8px;
            font-size: 8px;
        }
    }
`

const NavProfile = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
    grid-template-columns: repeat(2, 1fr);

    .bx {
        color: #fff;
        font-size: 42px;
        padding: 0;
        margin: 0;

        &:hover {
            color: #5cb85c;
        }
    }

    button {
        outline: none;
        border: none;
        padding: 0;
        margin-left: 8px;
        width: 100%;
        padding: 7px 15px;
    }

    @media (max-width: 576px) {
        justify-content: start;

        .bx {
            font-size: 30px;
            margin-top: 5px;
            margin-left: -13px;
        }

        button {
            font-size: 10px;
            padding: 5px 8px;
        }
    }
`

const NavDropDownProfile = styled.div`
    position: absolute;
    top: 70px;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 1px 1px 4px 3px rgb(0, 0, 0, 0.1);

    button {
        font-size: 14px;
        margin-left: 0;
    }

    @media (max-width: 576px) {
        top: 50px;
        padding: 5px;
        left: -20px;


        button {
            font-size: 10px;
            padding: 5px 8px;
            margin-left: 0;
        }
    }
`

const NavCenterChild = styled.div`
    padding-bottom: 10px;
    > ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 10px;
        padding-bottom: 0;
        margin: 0;
        grid-gap: 10px;
    }
`

const NavCenterItem = styled.li`
    min-height: 175px;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    padding-top: 10px;
`

const NavCenterImg = styled.div`
    width: 100%;
    height: 100px;
    
    h5 {
        text-align: center;
        font-size: 72px;
        color: blue;
    }
`

const NavCenterTitle = styled.div`
    padding-top: 5px;
    
    p {
        text-align: center;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`


const NavSectionChild = styled.div`
    width: 1200px;

    > ul {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10px;
        padding: 10px;
        margin: 0;
    }
`

const NavSectionItem = styled.div`
    height: 250px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    overflow: hidden;

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
`

const NavSectionImg = styled.div`
    height: 180px;
    overflow: hidden;

    h5 {
        overflow: hidden;

        img {
            width: 100%;
            height: 180px;
            transition: all 0.5s;
            object-position: center;
        }
    }
`

const NavSectionTitle = styled.div`
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 10px;

    p {
        font-size: 10px;
        text-align: center;
    }
`

const Branches = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding: 10px;
`


const BranchesItem = styled.div`
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
`

const BranchesImg = styled.div`
    overflow: hidden;
    height: 200px;

    h5 {
        overflow: hidden;

        img {
            width: 100%;
            object-position: center;
            transition: all 0.5s;
        }
    }
`

const BranchesTitle = styled.div`
    p {
        padding: 10px;
        text-align: center;
    }
`

const HeaderStyle = styled.div`
    padding-top: 10px;
    padding-left: 20px;
`

const Electron = styled.div`
    padding: 10px;

    ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        padding: 0;
        margin: 0;
    }
`

const ElectronItem = styled.div`
    width: 300px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
`

const ElectronImg = styled.div`
    h5 {
        width: 100%;
        text-align: center;
        font-size: 82px;
        color: blue;
    }
`

const ElectronTitle = styled.div`
    p {
        text-align: center;
    }
`

const SelectLanBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const MobileNav = styled.div`
    display: none;
    position: relative;

    @media (max-width: 576px) {
        display: block;
    }
`

const MobileNavButton = styled.div`
    position: relative;

    button {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .hamburger {
        cursor: pointer;
    }

    .hamburger input {
        display: none;
    }

    .hamburger svg {
        height: 3em;
        transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line {
        fill: none;
        stroke: white;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 3;
        
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line-top-bottom {
        stroke-dasharray: 12 63;
    }

    .hamburger input:checked + svg {
        transform: rotate(-45deg);
    }

    .hamburger input:checked + svg .line-top-bottom {
        stroke-dasharray: 20 300;
        stroke-dashoffset: -32.42;
    }
`

const MobileNavBox = styled.div`
    position: fixed;
    top: 60px;
    width: 100%;
    height: calc(100vh - 20px);
    background-color: #f2f2f2;
    z-index: 100;
`

const MobileNavMain = styled.div`
    padding: 10px;


    button {
        border: none;
        background-color: transparent;
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    ul {
        list-style-type: none;
        width: 90%;

        a {
            color: #000;

            > li {
                padding: 5px;
                width: 100%;
                border-bottom: 1px solid #0f0f0f;
            }
        }
    }
`