import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../api/axios";

const SingleJournal = () => {
  const params = useParams();
  const [sinleSlave, setSingleSlave] = useState({});
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`/api/Journal/${params.id}`)
      .then((res) => {
        setSingleSlave(res?.data?.result);
        axiosInstance.get('api/journal/articles/' + res?.data?.result.id).then((res1) => {
            setArticles(res1?.data?.result);
            }).catch((err) => {
                console.log(err);
            });}
      )
      .catch((err) => {
        console.log(err);
      });
      
  }, []);

  console.log(articles);
  
  return (
    <div>
      <Navbar />
      <div className="container">
        <SingleJournalBox>
          <h1>Jurnal haqida</h1>
          <div className="slave-single-page-grid">
            <div className="slave-single-page-item">
              <div className="slave-left">
              <img src={`${axiosInstance.getUri()}api/file/downloadimagefile/${sinleSlave?.imageId}/low`} alt="Journal"/>
              </div>
              {/* <div className="slave-right">
                <p>{sinleSlave?.work_name}</p>
              </div> */}
            </div>
            <div className="slave-single-page-item">
              <div className="slave-left">
                <b>Jurnal nomi</b>
              </div>
              <div className="slave-right">
                <p>{sinleSlave?.name}</p>
              </div>
            </div>
            <div className="slave-single-page-item">
              <div className="slave-left">
                <b>Jurnal turi</b>
              </div>
              <div className="slave-right">
                <p>{sinleSlave?.journalType?.name}</p>
              </div>
            </div>
            <div className="slave-single-page-item">
              <div className="slave-left">
                <b>Muassasa</b>
              </div>
              <div className="slave-right">
                <p>{sinleSlave?.institution?.name}</p>
              </div>
            </div>
            <div className="slave-single-page-item">
              <div className="slave-left">
                <b>Maqolalar</b>
              <div>
                {articles && articles?.map((article, index) => (
                    <div key={index} className="journal-article">
                        <p>{index+1}.  <Link to={`/book/${article?.id}`}>{article?.title}</Link></p>
                    </div>
                ))}
              </div>
              </div>
            </div>
          </div>
        </SingleJournalBox>
      </div>
      <Footer />
    </div>
  );
};

const SingleJournalBox = styled.div`
  margin-top: 125px;

  h1 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 40px;
  }
    img {
    width: 50%;
    margin = 20px auto;
}
`;

export default SingleJournal;
